.hero {
  &-section {
    padding-bottom: 144px;
    box-sizing: border-box;
      * {
        box-sizing: border-box;
      }
      &__header {
        display: block;
        margin-bottom: 70px;
        @include align_c;
      }
      &__heading {
        margin: 0;
        color: $colorTheme;
        font-family: $font;
        font-weight: 600;
        font-size: 36px;
        line-height: 55px;
      }
      &__text {
        margin: 10px 0 0;
        color: $colorBlack;
        font-size: 32px;
        font-weight: 400;
        line-height: 49px;
      }
  }
  &-slider {
    position: relative;
    width: 100%;
    @include smooth(.2s);
    &__element {
      width: 675px;
      height: 450px;
      opacity: .6;
      outline: 0;
      @include smooth(.2s);
        &:not(:last-child) {
          margin-right: 65px;
        }
        &-link {
          position: relative;
          @include link(100%);
          font-family: $font;
          outline: 0;
          overflow: hidden;
            &::after {
              content: '';
              position: absolute;
              left: 0;
              bottom: 0;
              display: block;
              width: 100%;
              height: 120px;
              opacity: 0;
              background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #313131 83.33%);
              @include smooth(.1s);
              transition-delay: .0s;
              z-index: 1;
            }
        }
        &-content {
          position: absolute;
          left: 0;
          bottom: 0;
          display: block;
          width: 100%;
          padding: 0 20px 20px;
          color: $colorWhite;
          font-size: 18px;
          @include align_c;
          opacity: 0;
          transform: translateY(10%);
          @include smooth(.1s);
          transition-delay: .0s;
          z-index: 2;
        }
        &-value {
          display: block;
          max-width: 530px;
          @include auto;
          @include inherit;
        }
        img {
          width: 100%;
          height: auto;
        }

      &.slick-center {
        opacity: 1;
          .hero-slider {
            &__element {
              &-link {
                &::after {
                  opacity: 1;
                  @include smooth(.2s);
                  transition-delay: .2s;
                }
              }
              &-content {
                transform: translateY(0);
                @include smooth(.5s);
                transition-delay: .3s;
                opacity: 1;
              }
            }
          }
      }
    }
    &__arrows {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      max-width: 865px;
      height: 0;
      transform: translate(-50%, -50%);
    }
    &__controls {
      display: flex;
      width: 100%;
      margin-top: 35px;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
        &-dots {
          & + .hero-slider__controls-more {
            margin-left: 15px;
          }
        }
        &-more {
          margin-top: 15px;
        }
    }
    &__more {
      position: relative;
      @include iblock(top);
      height: 40px;
      padding-left: 23px;
      padding-right: 51px;
      color: $colorPrimary;
      font-size: 16px;
      font-weight: 500;
      line-height: 40px;
      background-color: $colorWhite;
      border: 1px solid $colorBorder;
      border-radius: #{$radius * 5};
      @include smooth(.2s);
        .i {
          position: absolute;
          top: 50%;
          right: 22px;
          width: 19px;
          height: 12px;
          fill: $colorPrimary;
          transform: translateY(-50%);
          @include smooth(.2s);
        }
        &:hover {
          color: $colorWhite;
          background-color: $colorTheme;
          border-color: $colorTheme;
          .i {
            fill: $colorWhite;
          }
        }
    }

    &--hidden {
      height: 0;
      opacity: 0;
      overflow: hidden;
    }

    .slick {
      //arrows
      &-arrow {
        position: absolute;
        top: -65px;
        width: 60px;
        height: 60px;
        padding: 0;
        font-size: 0;
        @include align_c;
        background-color: $colorBlue;
        border: 0;
        @include round;
        box-shadow: none;
        z-index: 2;
        @include smooth(.2s);
        .i {
          width: 13px;
          height: 24px;
          fill: $colorWhite;
          vertical-align: middle;
          @include smooth(.2s);
        }
        &:hover {
          background-color: $colorTheme;
          .i {
            fill: $colorWhite;
          }
        }
      }
      &-prev {
        left: 0;
        padding-right: 4px;
      }
      &-next {
        right: 0;
        padding-left: 4px;
      }
      &-disabled {}
      //dots
      &-dots {
        display: block;
        width: 100%;
        margin: 0;
        padding: 0;
        font-size: 0;
        @include align_c;
          li {
            @include iblock(middle);
            margin: 15px 7.5px 0;
            &.slick-active {
              button {
                color: $colorWhite;
                background-color: $colorTheme;
                border-color: $colorTheme;
              }
            }
          }
          button {
            display: block;
            height: 40px;
            padding-left: 22px;
            padding-right: 22px;
            color: $colorPrimary;
            font-family: $font;
            font-size: 16px;
            font-weight: 500;
            line-height: 38px;
            background-color: $colorWhite;
            border: 1px solid $colorBorder;
            border-radius: #{$radius * 5};
            box-shadow: none;
            cursor: pointer;
            outline: 0;
            @include smooth(.2s);
          }
      }
    }
  }

  @include media($tabletWidth) {
    &-section {
      padding-bottom: 100px;
      &__header {
        margin-bottom: 45px;
      }
      &__heading {
        font-size: 22px;
        line-height: 34px;
      }
      &__text {
        font-size: 20px;
        line-height: 30px;
      }
    }
    &-slider {
      &__element {
        width: 525px;
        height: 350px;
          &:not(:last-child) {
            margin-right: 35px;
          }
          &-content {
            font-size: 14px;
        }
      }
      &__arrows {
        display: none;
      }
      &__controls {
        display: block;
        margin-top: 20px;
        &-dots {
          & + .hero-slider__controls-more {
            margin-left: 0;
          }
        }
        &-more {
          display: block;
          width: 100%;
          margin-top: 20px;
          @include align_c;
        }
      }
      &__more {
        height: 30px;
        font-size: 14px;
        line-height: 30px;
      }
      .slick {
        &-dots {
          li {
            margin: 10px 5px 0;
          }
          button {
            height: 30px;
            padding-left: 14px;
            padding-right: 14px;
            font-size: 14px;
            line-height: 28px;
            border-radius: 15px;
          }
        }
      }
    }
  }
  @include media($mobileWidth) {
    &-section {
      padding-bottom: 75px;
      &__header {
        margin-bottom: 40px;
      }
      &__heading {
        font-size: 18px;
        line-height: 27px;
      }
      &__text {
        font-size: 16px;
        line-height: 24px;
      }
    }
    &-slider {
      &__element {
        width: 226px;
        height: 150px;
          &:not(:last-child) {
            margin-right: 15px;
          }
          &-link {
            &::after {
              display: none;
            }
          }
          &-content {
            display: none;
          }
      }
      &__controls {
        margin-top: 10px;
        padding-left: 15px;
        padding-right: 15px;
          &-more {
            margin-top: 15px;
          }
      }

      .slick {
        //dots
        &-dots {
          li {
            margin-top: 15px;
              &.slick-active {
                button {
                  color: $colorWhite;
                  background-color: $colorTheme;
                  border-color: $colorTheme;
                }
              }
          }
          button {
            height: 30px;
            padding-left: 14px;
            padding-right: 14px;
            font-size: 14px;
            line-height: 28px;
          }
        }
      }
    }
  }
  @include media($mobileXsWidth) {
    &-slider {
      &__controls {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}