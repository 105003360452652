html,
body {
    width: 100%;
    min-width: 320px;
    margin: 0;
}

._hide,
._none,
[hidden] {
    display: none!important;
    visibility: hidden!important;
}

.container {
    box-sizing: border-box;
    @media all and (max-width: 767px) {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
}
.value {
    @include inherit;
}