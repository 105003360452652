.victory-banner {
  position: relative;
  width: 100%;
  height: 100px;
  font-size: 0;
  @include align_c;
  background: rgb(134,5,5);
  background: -moz-linear-gradient(left, rgba(134,5,5,1) 0%, rgba(188,5,5,1) 30%, rgba(188,5,5,1) 70%, rgba(134,5,5,1) 100%);
  background: -webkit-linear-gradient(left, rgba(134,5,5,1) 0%,rgba(188,5,5,1) 30%,rgba(188,5,5,1) 70%,rgba(134,5,5,1) 100%);
  background: linear-gradient(to right, rgba(134,5,5,1) 0%,rgba(188,5,5,1) 30%,rgba(188,5,5,1) 70%,rgba(134,5,5,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#860505', endColorstr='#860505',GradientType=1 );
    &__inside {
      position: absolute;
      top: 50%;
      width: 100%;
      height: 158px;
      font-size: 0;
      transform: translateY(-50%);
    }
    &__image,
    &__text {
      @include iblock(middle);
    }
    &__image {
      width: 349px;
      height: 158px;
        img {
          width: 100%;
          height: 100%;
        }
    }
    &__text {
      padding-left: 40px;
      color: $colorWhite;
      font-family: $font3;
      font-size: 80px;
      line-height: normal;
    }

    & + .hero-section {
      margin-top: 70px;
    }

    @include media($desktopXsWidth) {
      &__inside {
        height: 136px;
      }
      &__image {
        width: 300px;
        height: 136px;
      }
      &__text {
        font-size: 70px;
        padding-left: 30px;
      }
    }
    @include media($tabletWidth) {
      height: 60px;
      &__inside {
        height: 91px;
      }
      &__image {
        width: 200px;
        height: 91px;
      }
      &__text {
        font-size: 50px;
        padding-left: 30px;
        line-height: 62px;
      }

        & + .hero-section {
          margin-top: 40px;
        }
    }
    @include media($tabletXsWidth) {
      height: 50px;
      &__inside {
        height: 68px;
      }
      &__image {
        width: 150px;
        height: 68px;
      }
      &__text {
        font-size: 40px;
        line-height: 53px;
      }
    }
    @include media($mobileWidth) {
      height: 40px;
        &__inside {
          height: 59px;
        }
        &__image {
          width: 130px;
          height: 59px;
        }
        &__text {
          padding-left: 15px;
          font-size: 24px;
          line-height: 42px;
        }
    }
    @include media($mobileXsWidth) {
        &__inside {
          height: 45px;
        }
        &__image {
          width: 100px;
          height: 45px;
        }
        &__text {
          font-size: 22px;
        }
    }
}