
/* Font Families */

$font                 : 'Roboto', sans-serif;
$font2                : 'Roboto Slab', sans-serif;
$font3                : 'Pobeda-Bold', sans-serif;

/* Colors */

$colorTheme           : #AB0404;

$colorBlack           : #000000;
$colorWhite           : #ffffff;
$colorPrimary         : #333333;
$colorRed             : #ffff00;
$colorDark            : #313131;

$colorPrimary         : #4d4d4d;
$colorBorder          : #e5e5e5;
$colorBlue            : #005fc3;
$colorLightBlue       : #f1f5f8;

/* Media Queries */

$desktopHDWidth      : 1450px;
$desktopWidth        : 1285px;
$desktopXsWidth      : 1199px;
$tabletWidth         : 991px;
$tabletXsWidth       : 767px;
$mobileWidth         : 599px;
$mobileXsWidth       : 360px;

/* Other */

$radius              : 5px;
