._with-arrows {
  .slick {
    &-arrow {
      position: absolute;
      top: 50%;
      width: 60px;
      height: 60px;
      font-size: 0;
      @include align_c;
      background-color: $colorBlue;
      border: 0;
      @include round;
      box-shadow: none;
      z-index: 2;
      transform: translateY(-50%);
      @include smooth(.2s);
        .i {
          width: 13px;
          height: 24px;
          fill: $colorWhite;
          vertical-align: middle;
          @include smooth(.2s);
        }
        &:hover {
          background-color: $colorTheme;
            .i {
              fill: $colorWhite;
            }
        }
    }
    &-prev {
      left: 30%;
    }
    &-next {
      right: 30%;
    }

    &-disabled {}
  }
}