/* Font Families */
/* Colors */
/* Media Queries */
/* Other */
/*
    Personal mixins
*/
@font-face {
  font-family: "Pobeda-Bold";
  src: url("../fonts/Pobeda/bold/Pobeda-Bold.eot");
  src: url("../fonts/Pobeda/bold/Pobeda-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Pobeda/bold/Pobeda-Bold.woff") format("woff"), url("../fonts/Pobeda/bold/Pobeda-Bold.ttf") format("truetype"), url("../fonts/Pobeda/bold/Pobeda-Bold.svg#Pobeda-Bold") format("svg");
  font-style: normal;
  font-weight: normal;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

html,
body {
  width: 100%;
  min-width: 320px;
  margin: 0;
}

._hide,
._none,
[hidden] {
  display: none !important;
  visibility: hidden !important;
}

.container {
  box-sizing: border-box;
}

@media all and (max-width: 767px) {
  .container {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.value {
  color: inherit;
  font-size: inherit;
}

.i {
  display: inline-block;
  vertical-align: initial;
}

._with-arrows .slick-arrow {
  position: absolute;
  top: 50%;
  width: 60px;
  height: 60px;
  font-size: 0;
  text-align: center;
  background-color: #005fc3;
  border: 0;
  border-radius: 50%;
  box-shadow: none;
  z-index: 2;
  transform: translateY(-50%);
  transition: all 0.2s linear;
}

._with-arrows .slick-arrow .i {
  width: 13px;
  height: 24px;
  fill: #ffffff;
  vertical-align: middle;
  transition: all 0.2s linear;
}

._with-arrows .slick-arrow:hover {
  background-color: #AB0404;
}

._with-arrows .slick-arrow:hover .i {
  fill: #ffffff;
}

._with-arrows .slick-prev {
  left: 30%;
}

._with-arrows .slick-next {
  right: 30%;
}

.responsive-video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

.responsive-video iframe,
.responsive-video object,
.responsive-video embed {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.hero-section {
  padding-bottom: 144px;
  box-sizing: border-box;
}

.hero-section * {
  box-sizing: border-box;
}

.hero-section__header {
  display: block;
  margin-bottom: 70px;
  text-align: center;
}

.hero-section__heading {
  margin: 0;
  color: #AB0404;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 36px;
  line-height: 55px;
}

.hero-section__text {
  margin: 10px 0 0;
  color: #000000;
  font-size: 32px;
  font-weight: 400;
  line-height: 49px;
}

.hero-slider {
  position: relative;
  width: 100%;
  transition: all 0.2s linear;
}

.hero-slider__element {
  width: 675px;
  height: 450px;
  opacity: .6;
  outline: 0;
  transition: all 0.2s linear;
}

.hero-slider__element:not(:last-child) {
  margin-right: 65px;
}

.hero-slider__element-link {
  position: relative;
  display: block;
  height: 100%;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  outline: 0;
  overflow: hidden;
}

.hero-slider__element-link::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 120px;
  opacity: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #313131 83.33%);
  transition: all 0.1s linear;
  transition-delay: .0s;
  z-index: 1;
}

.hero-slider__element-content {
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  width: 100%;
  padding: 0 20px 20px;
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  opacity: 0;
  transform: translateY(10%);
  transition: all 0.1s linear;
  transition-delay: .0s;
  z-index: 2;
}

.hero-slider__element-value {
  display: block;
  max-width: 530px;
  margin-left: auto;
  margin-right: auto;
  color: inherit;
  font-size: inherit;
}

.hero-slider__element img {
  width: 100%;
  height: auto;
}

.hero-slider__element.slick-center {
  opacity: 1;
}

.hero-slider__element.slick-center .hero-slider__element-link::after {
  opacity: 1;
  transition: all 0.2s linear;
  transition-delay: .2s;
}

.hero-slider__element.slick-center .hero-slider__element-content {
  transform: translateY(0);
  transition: all 0.5s linear;
  transition-delay: .3s;
  opacity: 1;
}

.hero-slider__arrows {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 865px;
  height: 0;
  transform: translate(-50%, -50%);
}

.hero-slider__controls {
  display: flex;
  width: 100%;
  margin-top: 35px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.hero-slider__controls-dots + .hero-slider__controls-more {
  margin-left: 15px;
}

.hero-slider__controls-more {
  margin-top: 15px;
}

.hero-slider__more {
  position: relative;
  display: inline-block;
  vertical-align: top;
  height: 40px;
  padding-left: 23px;
  padding-right: 51px;
  color: #4d4d4d;
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 25px;
  transition: all 0.2s linear;
}

.hero-slider__more .i {
  position: absolute;
  top: 50%;
  right: 22px;
  width: 19px;
  height: 12px;
  fill: #4d4d4d;
  transform: translateY(-50%);
  transition: all 0.2s linear;
}

.hero-slider__more:hover {
  color: #ffffff;
  background-color: #AB0404;
  border-color: #AB0404;
}

.hero-slider__more:hover .i {
  fill: #ffffff;
}

.hero-slider--hidden {
  height: 0;
  opacity: 0;
  overflow: hidden;
}

.hero-slider .slick-arrow {
  position: absolute;
  top: -65px;
  width: 60px;
  height: 60px;
  padding: 0;
  font-size: 0;
  text-align: center;
  background-color: #005fc3;
  border: 0;
  border-radius: 50%;
  box-shadow: none;
  z-index: 2;
  transition: all 0.2s linear;
}

.hero-slider .slick-arrow .i {
  width: 13px;
  height: 24px;
  fill: #ffffff;
  vertical-align: middle;
  transition: all 0.2s linear;
}

.hero-slider .slick-arrow:hover {
  background-color: #AB0404;
}

.hero-slider .slick-arrow:hover .i {
  fill: #ffffff;
}

.hero-slider .slick-prev {
  left: 0;
  padding-right: 4px;
}

.hero-slider .slick-next {
  right: 0;
  padding-left: 4px;
}

.hero-slider .slick-dots {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 0;
  text-align: center;
}

.hero-slider .slick-dots li {
  display: inline-block;
  vertical-align: middle;
  margin: 15px 7.5px 0;
}

.hero-slider .slick-dots li.slick-active button {
  color: #ffffff;
  background-color: #AB0404;
  border-color: #AB0404;
}

.hero-slider .slick-dots button {
  display: block;
  height: 40px;
  padding-left: 22px;
  padding-right: 22px;
  color: #4d4d4d;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 38px;
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 25px;
  box-shadow: none;
  cursor: pointer;
  outline: 0;
  transition: all 0.2s linear;
}

@media all and (max-width: 991px) {
  .hero-section {
    padding-bottom: 100px;
  }
  .hero-section__header {
    margin-bottom: 45px;
  }
  .hero-section__heading {
    font-size: 22px;
    line-height: 34px;
  }
  .hero-section__text {
    font-size: 20px;
    line-height: 30px;
  }
  .hero-slider__element {
    width: 525px;
    height: 350px;
  }
  .hero-slider__element:not(:last-child) {
    margin-right: 35px;
  }
  .hero-slider__element-content {
    font-size: 14px;
  }
  .hero-slider__arrows {
    display: none;
  }
  .hero-slider__controls {
    display: block;
    margin-top: 20px;
  }
  .hero-slider__controls-dots + .hero-slider__controls-more {
    margin-left: 0;
  }
  .hero-slider__controls-more {
    display: block;
    width: 100%;
    margin-top: 20px;
    text-align: center;
  }
  .hero-slider__more {
    height: 30px;
    font-size: 14px;
    line-height: 30px;
  }
  .hero-slider .slick-dots li {
    margin: 10px 5px 0;
  }
  .hero-slider .slick-dots button {
    height: 30px;
    padding-left: 14px;
    padding-right: 14px;
    font-size: 14px;
    line-height: 28px;
    border-radius: 15px;
  }
}

@media all and (max-width: 599px) {
  .hero-section {
    padding-bottom: 75px;
  }
  .hero-section__header {
    margin-bottom: 40px;
  }
  .hero-section__heading {
    font-size: 18px;
    line-height: 27px;
  }
  .hero-section__text {
    font-size: 16px;
    line-height: 24px;
  }
  .hero-slider__element {
    width: 226px;
    height: 150px;
  }
  .hero-slider__element:not(:last-child) {
    margin-right: 15px;
  }
  .hero-slider__element-link::after {
    display: none;
  }
  .hero-slider__element-content {
    display: none;
  }
  .hero-slider__controls {
    margin-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .hero-slider__controls-more {
    margin-top: 15px;
  }
  .hero-slider .slick-dots li {
    margin-top: 15px;
  }
  .hero-slider .slick-dots li.slick-active button {
    color: #ffffff;
    background-color: #AB0404;
    border-color: #AB0404;
  }
  .hero-slider .slick-dots button {
    height: 30px;
    padding-left: 14px;
    padding-right: 14px;
    font-size: 14px;
    line-height: 28px;
  }
}

@media all and (max-width: 360px) {
  .hero-slider__controls {
    padding-left: 0;
    padding-right: 0;
  }
}

@media all and (max-width: 599px) {
  .anons_big {
    padding-left: 0;
    padding-right: 0;
  }
}

.library-list__item {
  position: relative;
}

.anniversary {
  width: 100%;
}

.anniversary * {
  box-sizing: border-box;
}

.anniversary-hero {
  padding-top: 10px;
  padding-bottom: 140px;
}

.anniversary-hero__heading {
  color: #313131;
}

.anniversary-hero__body {
  width: 100%;
  font-size: 0;
}

.anniversary-hero__text, .anniversary-hero__image {
  display: inline-block;
  vertical-align: top;
}

.anniversary-hero__text {
  width: calc(100% - 470px);
  padding-right: 30px;
  font-size: 19px;
  line-height: 30px;
}

.anniversary-hero__text p {
  margin: 0;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}

.anniversary-hero__text p + p {
  margin-top: 30px;
}

.anniversary-hero__image {
  width: 470px;
}

.anniversary-unit {
  width: 100%;
}

.anniversary-unit:not(:last-child) {
  margin-bottom: 80px;
}

.anniversary-unit > *:last-child {
  margin-bottom: 0;
}

.anniversary-section {
  width: 100%;
}

.anniversary-section__header {
  width: 100%;
  margin-bottom: 40px;
  font-size: 0;
}

.anniversary-section__header-date, .anniversary-section__header-details {
  display: inline-block;
  vertical-align: baseline;
}

.anniversary-section__header-date {
  position: relative;
  width: 205px;
  color: #005fc3;
  font-family: "Roboto Slab", sans-serif;
  font-size: 60px;
  font-weight: 600;
  line-height: 107px;
}

.anniversary-section__header-date .value {
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-bottom: 3px solid #005fc3;
}

.anniversary-section__header-date .value::before {
  content: '';
  position: absolute;
  right: 100%;
  bottom: -3px;
  display: block;
  width: 50vw;
  height: 1px;
  background-color: #005fc3;
}

.anniversary-section__header-date .value._desktop {
  display: inline-block;
}

.anniversary-section__header-date .value._mobile {
  display: none;
}

.anniversary-section__header-details {
  width: calc(100% - 205px);
}

.anniversary-section__header-heading {
  margin: 0;
  color: #313131;
  font-size: 50px;
  line-height: normal;
}

.anniversary-section__header._align-bottom .anniversary-section__header-date, .anniversary-section__header._align-bottom .anniversary-section__header-details {
  vertical-align: bottom;
}

.anniversary-section__header._align-top .anniversary-section__header-date, .anniversary-section__header._align-top .anniversary-section__header-details {
  vertical-align: top;
}

.anniversary-section__header._date-offset .anniversary-section__header-date {
  margin-bottom: 90px;
}

.anniversary-section__header._no-margin {
  margin-bottom: 0;
}

.anniversary-section__body {
  width: 100%;
  padding-left: 205px;
}

.anniversary-section__body::before, .anniversary-section__body::after {
  content: '';
  display: block;
  width: 100%;
  height: 0;
  visibility: hidden;
  clear: both;
}

.anniversary-section__body > *:first-child {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.anniversary-section__preview-text, .anniversary-section__detail-text {
  float: left;
  width: 100%;
  color: #000000;
}

.anniversary-section__preview-text._with-aside, .anniversary-section__detail-text._with-aside {
  width: calc(100% - 270px);
  padding-right: 30px;
}

.anniversary-section__preview-text p, .anniversary-section__detail-text p {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}

.anniversary-section__preview-text p + p, .anniversary-section__detail-text p + p {
  margin-top: 30px;
}

.anniversary-section__preview-text {
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 400;
  line-height: 38px;
}

.anniversary-section__detail-text {
  font-size: 19px;
  font-weight: 300;
  line-height: 30px;
}

.anniversary-section__detail-text + .anniversary-images {
  padding-top: 40px;
}

.anniversary-section__aside {
  float: right;
  width: 270px;
}

.anniversary-section__aside .product-element + .product-element {
  margin-top: 40px;
}

.anniversary-section._empty {
  margin-top: -200px;
  margin-bottom: 150px;
}

.anniversary-section._empty .anniversary-section__header {
  margin-bottom: 0;
}

.anniversary-section .anniversary-images {
  clear: both;
}

.anniversary-books {
  width: auto;
  min-width: 100%;
  margin: 0 -15px 30px;
  padding-top: 20px;
  font-size: 0;
  clear: both;
}

.anniversary-books .product-element {
  display: inline-block;
  vertical-align: top;
  width: calc(25% - 30px);
  margin: 20px 15px 0;
}

.anniversary-images {
  width: 100%;
  font-size: 0;
}

.anniversary-images__element {
  display: inline-block;
  vertical-align: top;
  width: calc(50% - 15px);
}

.anniversary-images__element:nth-child(even) {
  margin-left: 30px;
}

.anniversary-images__element figure {
  width: 100%;
  margin: 0;
}

.anniversary-images__element figcaption {
  display: block;
  margin-top: 30px;
  color: #000000;
  font-size: 19px;
  font-weight: 300;
  line-height: 30px;
}

.anniversary-images__element img {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: auto;
  border: 0;
}

@media all and (max-width: 1450px) {
  .anniversary-images__element figcaption {
    font-size: 18px;
  }
}

@media all and (max-width: 1199px) {
  .anniversary-hero {
    padding-bottom: 100px;
  }
  .anniversary-hero__heading {
    margin-bottom: 40px;
    font-size: 32px;
    line-height: normal;
  }
  .anniversary-hero__text {
    display: block;
    width: 100%;
    padding-right: 0;
    font-size: 16px;
    line-height: 25px;
  }
  .anniversary-hero__text p + p {
    margin-top: 20px;
  }
  .anniversary-hero__image {
    display: none;
  }
  .anniversary-unit:not(:last-child) {
    margin-bottom: 60px;
  }
  .anniversary-section__header-date {
    width: 100px;
    font-size: 30px;
    line-height: 53px;
  }
  .anniversary-section__header-date:empty {
    display: none;
  }
  .anniversary-section__header-date .value._desktop {
    display: none;
  }
  .anniversary-section__header-date .value._mobile {
    display: inline-block;
  }
  .anniversary-section__header-details {
    width: calc(100% - 100px);
  }
  .anniversary-section__header-heading {
    font-size: 30px;
    line-height: normal;
  }
  .anniversary-section__header._no-margin {
    margin-bottom: 40px;
  }
  .anniversary-section__header._no-margin .anniversary-section__header-date {
    width: auto;
  }
  .anniversary-section__header._no-margin .anniversary-section__header-details {
    display: none;
  }
  .anniversary-section__body {
    padding-left: 0;
  }
  .anniversary-section__preview-text p + p, .anniversary-section__detail-text p + p {
    margin-top: 20px;
  }
  .anniversary-section__preview-text {
    font-size: 20px;
    line-height: 32px;
  }
  .anniversary-section__detail-text {
    font-size: 16px;
    line-height: 25px;
  }
  .anniversary-section._empty {
    display: none;
  }
  .anniversary-section + .anniversary-section {
    margin-top: 30px;
  }
}

@media all and (max-width: 991px) {
  .anniversary-section__preview-text._with-aside {
    float: none;
    width: 100%;
    padding-right: 0;
    clear: both;
  }
  .anniversary-section__detail-text._with-aside {
    width: calc(100% - 170px);
  }
  .anniversary-section__aside {
    width: 170px;
  }
  .anniversary-books .product-element {
    width: calc(50% - 30px);
  }
}

@media all and (max-width: 599px) {
  .anniversary-hero {
    padding-bottom: 50px;
  }
  .anniversary-hero__heading {
    margin-bottom: 30px;
    font-size: 20px;
  }
  .anniversary-hero__text {
    font-size: 14px;
    line-height: 22px;
  }
  .anniversary-unit:not(:last-child) {
    margin-bottom: 50px;
  }
  .anniversary-section__header {
    margin-bottom: 30px;
  }
  .anniversary-section__header-date, .anniversary-section__header-details {
    display: block;
    width: 100%;
  }
  .anniversary-section__header-date {
    margin-bottom: 10px;
  }
  .anniversary-section__header-heading {
    font-size: 20px;
  }
  .anniversary-section__body {
    display: flex;
    flex-wrap: wrap;
  }
  .anniversary-section__aside {
    float: none;
    width: 100%;
    margin-top: 30px;
    order: 3;
  }
  .anniversary-section__preview-text {
    font-size: 16px;
    line-height: 25px;
    order: 1;
  }
  .anniversary-section__detail-text {
    font-size: 14px;
    line-height: 22px;
    order: 2;
  }
  .anniversary-section__detail-text._with-aside {
    float: none;
    width: 100%;
    padding-right: 0;
  }
  .anniversary-section._no-margin .anniversary-section__header {
    margin-bottom: 30px;
  }
  .anniversary-section .anniversary-images {
    padding-top: 30px;
    order: 5;
  }
  .anniversary-books {
    margin: 40px 0 0;
    padding-top: 0;
    order: 4;
  }
  .anniversary-books .product-element {
    width: 100%;
    margin: 0;
  }
  .anniversary-books .product-element:not(:last-child) {
    margin-bottom: 40px;
  }
  .anniversary-images__element {
    display: block;
    width: 100%;
  }
  .anniversary-images__element:nth-child(even) {
    margin-left: 0;
  }
  .anniversary-images__element:not(:last-child) {
    margin-bottom: 40px;
  }
  .anniversary-images__element figcaption {
    margin-top: 20px;
    font-size: 14px;
    line-height: 22px;
  }
}

.product-element {
  width: 100%;
  background-color: #f1f5f8;
}

.product-element__link {
  display: block;
  height: auto;
  text-decoration: none;
  padding: 40px 30px 30px;
}

.product-element__image {
  display: block;
  max-height: 200px;
  margin-bottom: 40px;
}

.product-element__image img {
  max-height: 200px;
  margin-left: auto;
  margin-right: auto;
}

.product-element__heading {
  color: #005fc3;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  border-bottom: 1px solid rgba(0, 95, 195, 0.4);
  transition: all 0.2s linear;
}

.product-element:hover .product-element__heading {
  border-bottom-color: transparent;
}

@media all and (max-width: 991px) {
  .product-element__link {
    padding: 30px 20px;
  }
  .product-element__image {
    max-height: 160px;
    margin-bottom: 30px;
  }
  .product-element__image img {
    max-height: 160px;
  }
  .product-element__heading {
    font-size: 14px;
    line-height: 21px;
  }
}

@media all and (max-width: 599px) {
  .product-element__link {
    padding: 40px 30px;
  }
  .product-element__image {
    max-height: 200px;
    margin-bottom: 40px;
  }
  .product-element__image img {
    max-height: 200px;
  }
  .product-element__heading {
    font-size: 16px;
    line-height: 24px;
  }
}

.victory-banner {
  position: relative;
  width: 100%;
  height: 100px;
  font-size: 0;
  text-align: center;
  background: #860505;
  background: -moz-linear-gradient(left, #860505 0%, #bc0505 30%, #bc0505 70%, #860505 100%);
  background: -webkit-linear-gradient(left, #860505 0%, #bc0505 30%, #bc0505 70%, #860505 100%);
  background: linear-gradient(to right, #860505 0%, #bc0505 30%, #bc0505 70%, #860505 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#860505', endColorstr='#860505',GradientType=1 );
}

.victory-banner__inside {
  position: absolute;
  top: 50%;
  width: 100%;
  height: 158px;
  font-size: 0;
  transform: translateY(-50%);
}

.victory-banner__image, .victory-banner__text {
  display: inline-block;
  vertical-align: middle;
}

.victory-banner__image {
  width: 349px;
  height: 158px;
}

.victory-banner__image img {
  width: 100%;
  height: 100%;
}

.victory-banner__text {
  padding-left: 40px;
  color: #ffffff;
  font-family: "Pobeda-Bold", sans-serif;
  font-size: 80px;
  line-height: normal;
}

.victory-banner + .hero-section {
  margin-top: 70px;
}

@media all and (max-width: 1199px) {
  .victory-banner__inside {
    height: 136px;
  }
  .victory-banner__image {
    width: 300px;
    height: 136px;
  }
  .victory-banner__text {
    font-size: 70px;
    padding-left: 30px;
  }
}

@media all and (max-width: 991px) {
  .victory-banner {
    height: 60px;
  }
  .victory-banner__inside {
    height: 91px;
  }
  .victory-banner__image {
    width: 200px;
    height: 91px;
  }
  .victory-banner__text {
    font-size: 50px;
    padding-left: 30px;
    line-height: 62px;
  }
  .victory-banner + .hero-section {
    margin-top: 40px;
  }
}

@media all and (max-width: 767px) {
  .victory-banner {
    height: 50px;
  }
  .victory-banner__inside {
    height: 68px;
  }
  .victory-banner__image {
    width: 150px;
    height: 68px;
  }
  .victory-banner__text {
    font-size: 40px;
    line-height: 53px;
  }
}

@media all and (max-width: 599px) {
  .victory-banner {
    height: 40px;
  }
  .victory-banner__inside {
    height: 59px;
  }
  .victory-banner__image {
    width: 130px;
    height: 59px;
  }
  .victory-banner__text {
    padding-left: 15px;
    font-size: 24px;
    line-height: 42px;
  }
}

@media all and (max-width: 360px) {
  .victory-banner__inside {
    height: 45px;
  }
  .victory-banner__image {
    width: 100px;
    height: 45px;
  }
  .victory-banner__text {
    font-size: 22px;
  }
}
