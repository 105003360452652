.product-element {
  width: 100%;
  background-color: $colorLightBlue;
    &__link {
      @include link();
      padding: 40px 30px 30px;
    }
    &__image {
      display: block;
      max-height: 200px;
      margin-bottom: 40px;
        img {
          max-height: 200px;
          @include auto;
        }
    }
    &__heading {
      color: $colorBlue;
      font-size: 16px;
      line-height: 24px;
      text-decoration: none;
      border-bottom: 1px solid rgba($colorBlue, .4);
      @include smooth(.2s)
    }

    &:hover {
      .product-element {
        &__heading {
          border-bottom-color: transparent;
        }
      }
    }

    @include media($tabletWidth) {
      &__link {
        padding: 30px 20px;
      }
      &__image {
        max-height: 160px;
        margin-bottom: 30px;
          img {
            max-height: 160px;
          }
      }
      &__heading {
        font-size: 14px;
        line-height: 21px;
      }
    }
    @include media($mobileWidth) {
      &__link {
        padding: 40px 30px;
      }
      &__image {
        max-height: 200px;
        margin-bottom: 40px;
        img {
          max-height: 200px;
        }
      }
      &__heading {
        font-size: 16px;
        line-height: 24px;
      }
    }
}