@mixin list-reset {
    list-style: none;
    margin: 0;
    padding: 0;
}

@mixin center($position) {
    position: absolute;

    @if $position == 'vertical' {
        top: 50%;
        transform: translateY(-50%);
    }
    @else if $position == 'horizontal' {
        left: 50%;
        transform: translate(-50%);
    }
    @else if $position == 'both' {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

@mixin iblock($align: initial) {
    display: inline-block;
    vertical-align: $align;
}

@mixin clearfix {
    &:after {
        content: '';
        display: table;
        clear: both;
    }
}

@mixin optional-at-root($sel) {
    @at-root #{if(not &, $sel, selector-append(&, $sel))} {
        @content;
    }
}

@mixin placeholder {
    @include optional-at-root('::-webkit-input-placeholder') {
        @content;
    }

    @include optional-at-root(':-moz-placeholder') {
        @content;
    }

    @include optional-at-root('::-moz-placeholder') {
        @content;
    }

    @include optional-at-root(':-ms-input-placeholder') {
        @content;
    }
}

@mixin fontFace($family, $src, $style: normal, $weight: normal) {
    @font-face {
        font-family: $family;
        src: url('#{$src}.eot');
        src: url('#{$src}.eot?#iefix') format('embedded-opentype'),
             url('#{$src}.woff') format('woff'),
             url('#{$src}.ttf') format('truetype'),
             url('#{$src}.svg##{$family}') format('svg');

        font-style: $style;
        font-weight: $weight;
    }
}

@mixin media($mediaWidth) {
    @media all and (max-width: $mediaWidth) {
        @content;
    }
}

/*
    Personal mixins
*/

@mixin fix {
    content: '';
    display: block;
    width: 100%;
    height: 0;
    visibility: hidden;
    clear: both;
}
@mixin smooth($var) {
    transition: all $var linear;
}
@mixin table($hi: 100%) {
    display: table;
    width: 100%;
    height: $hi;
    table-layout: fixed;
}
@mixin td($align){
    display: table-cell;
    vertical-align: $align;
}
@mixin iblock($align: initial) {
    display: inline-block;
    vertical-align: $align;
}
@mixin noselect {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
@mixin align_l {
    text-align: left;
}
@mixin align_c {
    text-align: center;
}
@mixin align_r {
    text-align: right;
}
@mixin align_j {
    text-align: justify;
}
@mixin bold {
    font-weight: bold;
}
@mixin upp {
    text-transform: uppercase;
}
@mixin image($width, $m_width) {
    width: $width;
    max-width: $m_width;
}
@mixin link($hi: auto) {
    display: block;
    height: $hi;
    text-decoration: none;
}
@mixin auto {
    margin-left: auto;
    margin-right: auto;
}
@mixin inherit {
    color: inherit;
    font-size: inherit;
}
@mixin round {
    border-radius: 50%;
}
@mixin input($hi, $padding) {
    display: block;
    width: 100%;
    height: $hi;
    padding: $padding;
    font-size: 1.6rem;
    background: transparent;
    border: 1px solid transparent;
    box-shadow: none;
    border-radius: .4rem;
    outline: 0;
    resize: none;
    appearance: none;
    @include smooth(.2s);
}
@mixin button($width, $hi, $padding, $bg, $bc, $br) {
    @include iblock(top);
    width: $width;
    height: $hi;
    padding: $padding;
    color: $colorWhite;
    font-family: $font;
    font-size: 1.6rem;
    font-weight: 300;
    @include align_c;
    text-decoration: none;
    line-height: 1;
    background: $bg;
    border: 1px solid $bc;
    cursor: pointer;
    border-radius: $br;
    outline: 0;
    @include noselect;
    @include smooth(.2s);
}
@mixin full {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

