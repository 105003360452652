.anniversary {
  width: 100%;
    * {
      box-sizing: border-box;
    }
    &-hero {
      padding-top: 10px;
      padding-bottom: 140px;
      &__heading {
        color: $colorDark;
      }
      &__body {
        width: 100%;
        font-size: 0;
      }
      &__text,
      &__image {
        @include iblock(top);
      }
      &__text {
        width: calc(100% - 470px);
        padding-right: 30px;
        font-size: 19px;
        line-height: 30px;
          p {
            margin: 0;
            @include inherit;
            line-height: inherit;
              & + p {
                margin-top: 30px;
              }
          }
      }
      &__image {
        width: 470px;
      }
    }
    &-unit {
      width: 100%;
        &:not(:last-child) {
          margin-bottom: 80px;
        }
        & > *:last-child {
          margin-bottom: 0;
        }
    }
    &-section {
      width: 100%;
        &__header {
          width: 100%;
          margin-bottom: 40px;
          font-size: 0;
            &-date,
            &-details {
              @include iblock(baseline);
            }
            &-date {
              position: relative;
              width: 205px;
              color: $colorBlue;
              font-family: $font2;
              font-size: 60px;
              font-weight: 600;
              line-height: 107px;
                .value {
                  position: relative;
                  @include iblock(top);
                  border-bottom: 3px solid $colorBlue;
                    &::before {
                      content: '';
                      position: absolute;
                      right: 100%;
                      bottom: -3px;
                      display: block;
                      width: 50vw;
                      height: 1px;
                      background-color: $colorBlue;
                    }

                    &._desktop {
                      display: inline-block;
                    }
                    &._mobile {
                      display: none;
                    }
                }
            }
            &-details {
              width: calc(100% - 205px);
            }
            &-heading {
              margin: 0;
              color: $colorDark;
              font-size: 50px;
              line-height: normal;
            }

            &._align-bottom {
              .anniversary-section__header {
                &-date,
                &-details {
                  vertical-align: bottom;
                }
              }
            }
            &._align-top {
              .anniversary-section__header {
                &-date,
                &-details {
                  vertical-align: top;
                }
              }
            }
            &._date-offset {
              .anniversary-section__header {
                &-date {
                  margin-bottom: 90px;
                }
              }
            }
            &._no-margin {
              margin-bottom: 0;
            }
        }
        &__body {
          width: 100%;
          padding-left: 205px;
            &::before,
            &::after {
              @include fix;
            }

            & > *:first-child {
              margin-top: 0!important;
              padding-top: 0!important;
            }
        }
        &__preview-text,
        &__detail-text {
          float: left;
          width: 100%;
          color: $colorBlack;
            &._with-aside {
              width: calc(100% - 270px);
              padding-right: 30px;
            }
            p {
              margin: 0;
              @include inherit;
              font-weight: inherit;
              line-height: inherit;
                & + p {
                  margin-top: 30px;
                }
            }
        }
        &__preview-text {
          margin-bottom: 30px;
          font-size: 24px;
          font-weight: 400;
          line-height: 38px;
        }
        &__detail-text {
          font-size: 19px;
          font-weight: 300;
          line-height: 30px;

            & + .anniversary-images {
              padding-top: 40px;
            }
        }
        &__aside {
          float: right;
          width: 270px;
            .product-element {
              & + .product-element {
                margin-top: 40px;
              }
            }
        }

        &._empty {
          margin-top: -200px;
          margin-bottom: 150px;
            .anniversary-section__header {
              margin-bottom: 0;
            }
        }

        .anniversary-images {
          clear: both;
        }
    }
    &-books {
      width: auto;
      min-width: 100%;
      margin: 0 -15px 30px;
      padding-top: 20px;
      font-size: 0;
      clear: both;
        .product-element {
          @include iblock(top);
          width: calc(25% - 30px);
          margin: 20px 15px 0;
        }
    }
    &-images {
      width: 100%;
      font-size: 0;
        &__element {
          @include iblock(top);
          width: calc(50% - 15px);
            &:nth-child(even) {
              margin-left: 30px;
            }

            figure {
              width: 100%;
              margin: 0;
            }
            figcaption {
              display: block;
              margin-top: 30px;
              color: $colorBlack;
              font-size: 19px;
              font-weight: 300;
              line-height: 30px;
            }
            img {
              @include iblock(top);
              width: 100%;
              height: auto;
              border: 0;
            }
        }
    }

    @include media(1450px) {
      &-images {
        &__element {
          figcaption {
            font-size: 18px;
          }
        }
      }
    }
    @include media($desktopXsWidth) {
      &-hero {
        padding-bottom: 100px;
          &__heading {
            margin-bottom: 40px;
            font-size: 32px;
            line-height: normal;
          }
          &__text {
            display: block;
            width: 100%;
            padding-right: 0;
            font-size: 16px;
            line-height: 25px;
            p {
              & + p {
                margin-top: 20px;
              }
            }
          }
          &__image {
            display: none;
          }
      }
      &-unit {
        &:not(:last-child) {
          margin-bottom: 60px;
        }
      }
      &-section {
        &__header {
          &-date {
            width: 100px;
            font-size: 30px;
            line-height: 53px;
              &:empty {
                display: none;
              }
              .value {
                &._desktop {
                  display: none;
                }
                &._mobile {
                  display: inline-block;
                }
              }
          }
          &-details {
            width: calc(100% - 100px);
          }
          &-heading {
            font-size: 30px;
            line-height: normal;
          }

          &._no-margin {
            margin-bottom: 40px;

              .anniversary-section {
                &__header {
                  &-date {
                    width: auto;
                  }
                  &-details {
                    display: none;
                  }
                }
              }
          }
        }
        &__body {
          padding-left: 0;
        }
        &__preview-text,
        &__detail-text {
          p {
            & + p {
              margin-top: 20px;
            }
          }
        }
        &__preview-text {
          font-size: 20px;
          line-height: 32px;
        }
        &__detail-text {
          font-size: 16px;
          line-height: 25px;
        }

        &._empty {
          display: none;
        }
        & + .anniversary-section {
          margin-top: 30px;
        }
      }
    }
    @include media($tabletWidth) {
      &-section {
        &__preview-text {
          &._with-aside {
            float: none;
            width: 100%;
            padding-right: 0;
            clear: both;
          }
        }
        &__detail-text {
          &._with-aside {
            width: calc(100% - 170px);
          }
        }
        &__aside {
          width: 170px;
        }
      }
      &-books {
        .product-element {
          width: calc(50% - 30px);
        }
      }
    }
    @include media($mobileWidth) {
      &-hero {
        padding-bottom: 50px;
        &__heading {
          margin-bottom: 30px;
          font-size: 20px;
        }
        &__text {
          font-size: 14px;
          line-height: 22px;
        }
      }
      &-unit {
        &:not(:last-child) {
          margin-bottom: 50px;
        }
      }
      &-section {
        &__header {
          margin-bottom: 30px;
            &-date,
            &-details {
              display: block;
              width: 100%;
            }
            &-date {
              margin-bottom: 10px;
            }
            &-heading {
              font-size: 20px;
            }
        }
        &__body {
          display: flex;
          flex-wrap: wrap;
        }
        &__aside {
          float: none;
          width: 100%;
          margin-top: 30px;
          order: 3;
        }
        &__preview-text {
          font-size: 16px;
          line-height: 25px;
          order: 1;
        }
        &__detail-text {
          font-size: 14px;
          line-height: 22px;
          order: 2;
            &._with-aside {
              float: none;
              width: 100%;
              padding-right: 0;
            }
        }

        &._no-margin {
          .anniversary-section__header {
            margin-bottom: 30px;
          }
        }
        .anniversary-images {
          padding-top: 30px;
          order: 5;
        }
      }
      &-books {
        margin: 40px 0 0;
        padding-top: 0;
        order: 4;
          .product-element {
            width: 100%;
            margin: 0;
              &:not(:last-child) {
                margin-bottom: 40px;
              }
          }
      }
      &-images {
        &__element {
          display: block;
          width: 100%;
            &:nth-child(even) {
              margin-left: 0;
            }
            &:not(:last-child) {
              margin-bottom: 40px;
            }
          figcaption {
            margin-top: 20px;
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
    }
}